import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import toast from "react-hot-toast"

import eyeIcon from "../../../assets/images/icons/eye-icon.svg"
import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"
import ManageTrailer from "./ManageTrailer"
import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import { GetTrailersListing, UpdateTrailer } from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import {
   TrailerPublishStatus,
   TrailerSize,
   TrailerStatus,
} from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import TrailerModels from "constants/TrailerModels"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"

const COLUMNS = {
   TRAILER_ID: "Trailer Id",
   // TRAILER_NAME: "Trailer Name",
   SIZE: "Trailer Size",
   TRAILER_TYPE: "Trailer Type",
   MODEL: "Trailer Model",
   BASE_PRICE: "Base Price",
   PUBLISH_DATE: "Go Live Date",
   PUBLISH_STATUS: "Publish Status",
   TRAILER_YEAR: "Year",
   STATUS: "Status",
   ACTIONS: "Actions",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const TrailersListing = ({}) => {
   const history = useHistory()
   const [statusFilters, setStatusFilters] = useState(null)
   const [selection, setSelection] = useState({})

   const {
      data,
      isFetching,
      page,
      searchText,
      pageSize,
      total,
      handleSearchChange,
      request,
      handlePageClick,
      onChangePageSize,
      setTableDataChangeLoading,
      tableDataChangeLoading,
      setData,
   } = useLocalPaginatedRequest({
      requestFn: GetTrailersListing,
      params: {
         ...(statusFilters?.value && { status: statusFilters?.value }),
      },
      deps: [statusFilters],
      dataKey: DATA_KEY,
      totalKey: TOTAL_KEY,
   })

   const [eventsType, setEventsType] = useState({
      isManage: null,
      isUpdate: null,
      isDetails: null,
   })

   const handleInitManage = obj => {
      setEventsType(prev => ({ ...prev, isManage: true }))
      setSelection(obj)
   }

   const handleInitViewDetails = obj => {
      setSelection(obj)
      setEventsType(prev => ({ ...prev, isDetails: true, isManage: true }))
   }

   const handleNavigateToSettings = obj => {
      setSelection(obj)

      return history.push({
         pathname: getRoute(APP_ROUTES.CONFIGURATOR_TRAILER_SETTINGS_BUILD, {
            trailerId: obj.id,
         }),
         state: "",
      })
   }
   const handleSelectStatusOption = option => {
      setStatusFilters(option)
   }

   const handleStatusChange = async (currStatus, id) => {
      let status =
         currStatus === TrailerStatus.Active
            ? TrailerStatus.Inactive
            : TrailerStatus.Active
      let dataClone = [...data]
      dataClone.map(el => {
         if (el?.id == id) el.status = status
      })
      setData(dataClone)
      let payload = {
         status: status,
      }
      try {
         setTableDataChangeLoading(true)
         let res = await UpdateTrailer(id, payload)
         toast.success("Trailer status has been updated !")
      } catch (err) {
         toast.error(err.message)
         let dataClone = [...data]
         dataClone.map(el => {
            if (el?.id == id) el.status = currStatus
         })
         setData(dataClone)
      } finally {
         setTableDataChangeLoading(false)
      }
   }

   return (
      <>
         <div className="mb-3 trailer-container">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
               <h1 className="header-title mb-0">Configurator Settings</h1>

               <Button
                  className={`header-button headerButtonResponsive`}
                  title="Add Trailer"
                  onClick={() => handleInitManage({})}
               />
            </div>
         </div>

         <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
               <div className="status-search-con wh-im-dpgi-status  me-2 ">
                  <AutoComplete
                     value={statusFilters}
                     isClearable
                     onChange={val => handleSelectStatusOption(val)}
                     isSearchable={false}
                     placeholder="Select status"
                     customStyles={customStyles.AdminAppSelectStyles}
                     classNamePrefix="status-header-search-admin"
                     options={convertToSelectOptions(TrailerStatus)}
                  />
               </div>
               <div className="ad-location-search-box">
                  <SearchBox
                     inputClasses="vendor-search-input input-search"
                     labelClass="d-none"
                     placeholder="Search"
                     searchIcon={true}
                     searchIconClass="vendor-table-search-icon"
                     conClass="vendor-table-search-inner-class"
                     searchText={searchText}
                     onChange={handleSearchChange}
                  />
               </div>
            </div>
         </div>
         <Row>
            <Col md={12}>
               <DataTable
                  data={data}
                  tableClasses="trailer-management-table"
                  className="table-responsive"
                  loading={isFetching}
                  config={[
                     {
                        title: COLUMNS.TRAILER_ID,
                        className: "",
                        render: data => data.id || "-",
                     },
                     //  {
                     //     title: COLUMNS.TRAILER_NAME,
                     //     className: "",
                     //     render: data => data.name || "-",
                     //  },
                     {
                        title: COLUMNS.SIZE,
                        className: "",
                        render: data =>
                           data.configuratorTrailerSizeType.map((item, ind) => (
                              <span key={ind}>
                                 {ind != 0 && ", "}
                                 {TrailerSize.getDisplayTextKey(item)}
                              </span>
                           )),
                     },
                     {
                        title: COLUMNS.TRAILER_TYPE,
                        className: "",
                        render: data => data?.trailerType?.trailerType || "-",
                     },
                     {
                        title: COLUMNS.MODEL,
                        className: "",

                        render: data =>
                           data.model.map((item, ind) => (
                              <span key={ind}>
                                 {ind != 0 && ", "}
                                 {TrailerModels.getDisplayTextKey(item)}
                              </span>
                           )),
                     },
                     {
                        title: COLUMNS.BASE_PRICE,
                        className: "",
                        render: data =>
                           `$${Number(data?.price).toFixed(2)}` || "0",
                     },

                     {
                        title: COLUMNS.TRAILER_YEAR,
                        className: "",
                        render: data => data?.trailerYear,
                     },

                     {
                        title: COLUMNS.PUBLISH_DATE,
                        className: "",
                        render: data =>
                           convertDateTime({
                              date: data.publishingDate,
                              customFormat: dateTimeFormat.apiDateFormat,
                              dateOnly: true,
                           }),
                     },

                     {
                        title: COLUMNS.PUBLISH_STATUS,
                        className: "",
                        render: data =>
                           TrailerPublishStatus.getDisplayTextKey(
                              data.publishingStatus
                           ),
                     },

                     {
                        title: COLUMNS.STATUS,
                        className: "",
                        render: data => (
                           <ActiveInactiveSwitch
                              isDisable={tableDataChangeLoading}
                              id={data.id}
                              name={`${data.id}`}
                              onChange={e =>
                                 handleStatusChange(data?.status, data?.id)
                              }
                              isChecked={data?.status == TrailerStatus.Active}
                           />
                        ),
                     },

                     {
                        title: COLUMNS.ACTIONS,

                        render: data => (
                           <div className="d-flex gap-2 pointer">
                              <img
                                 src={eyeIcon}
                                 onClick={() => handleInitViewDetails(data)}
                              />

                              <img
                                 src={editIcon}
                                 onClick={() => handleInitManage(data)}
                              />

                              <img
                                 src={settingsIcon}
                                 onClick={() => handleNavigateToSettings(data)}
                              />
                              {/* :TODO: Remove on prod */}
                              <a
                                 target="_blank"
                                 rel="noreferrer"
                                 href={`https://configurator.casitatraveltrailers.com/configurator/${data.id}/size-model`}
                              >
                                 Configurator
                              </a>
                           </div>
                        ),
                     },
                  ]}
               />
            </Col>
            <Pagination
               currentPage={page}
               totalData={total}
               onPageClick={handlePageClick}
            />
         </Row>

         <ManageTrailer
            isDetailView={eventsType.isDetails}
            isOpen={eventsType.isManage}
            id={selection?.id}
            onSuccess={request}
            onToggleModal={() => {
               setSelection({})
               setEventsType(prev => ({
                  ...prev,
                  isManage: false,
                  isDetails: false,
               }))
            }}
         />
      </>
   )
}

export default TrailersListing
